<template>
  <div>
    <el-card>
      <div slot="header" class="flex flex-between flex-v-center">
        <div class="flex flex-v-center">
          <h4 class="pointer"
            :class="($store.getters.getVip() == 'VIP 已过期' || $store.getters.getVip() == '未购买VIP') ? 'color-gray' : 'color-green'">
            {{
              $store.getters.getVip()
            }}</h4>
        </div>
        <div class="flex flex-v-center">

          <el-link type="primary" style="font-size: 20px;" icon="el-icon-time" @click="to(1)"><span class="mt">{{
            $t('购买记录') }}</span></el-link>
          <el-link type="primary" style="font-size: 20px; margin-left: 50px;" icon="el-icon-s-ticket"
            @click="to(0)"><span class="mt">{{ $t('兑换码') }}</span></el-link>
        </div>

      </div>
      <h2 class="flex flex-v-center flex-h-center">
        <el-image style="width:30px;" src="/web/image/vip3.png"></el-image> {{ $t('海澳') }}<span
          class="color-green margin-sx">VIP</span>{{ $t('会员') }}<el-image style="width:30px;"
          src="/web/image/vip3.png"></el-image>
      </h2>
      <div class="margin-lt flex">
        <div class="flex1 flex flex-column" style="margin:0 10px" v-for="(vip, index) in $store.getters.getVips()"
          :key="index">
          <el-card @click.native="tab1 = index" shadow="hover" class="pointer br flex1" style="position: relative;"
            :class="tab1 == index ? 'checked' : ''">
            <div>
              <div class="flex flex-v-center flex-h-center margin-t">
                <h2>{{ vip.title.split('/')[0] }}</h2>
                <div class="margin-ls">{{ vip.title.split('/')[1] }}</div>
              </div>
              <div class="price margin-lt center"><span class="price1">￥</span>{{ vip.money }}</div>
              <div class="st center margin-t">{{ vip.tip }}</div>

              <div class="status flex flex-v-center color-white" style="background-color: #db7032;"
                v-if="vip.status == 1">
                <span>{{ $t('推荐') }}</span>
                <el-image style="height:20px;" src="/web/image/vip2.png"></el-image>
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <div class="lt margin-lt">{{ $t('支付方式') }}</div>

      <el-row class="margin-t pay-type" :gutter="40">


        <el-col :span="6">
          <el-card :shadow="tab2 == 2 ? 'always' : 'hover'" class="pointer br" :class="tab2 == 2 ? 'checked1' : ''">
            <div @click="tab2 = 2" class="flex flex-v-center">
              <el-image src="/web/image/wx.png?v=2" style="height:30px" fit="contain" class="margin-x"></el-image>
              <span>{{ $t('微信支付') }}</span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card :shadow="tab2 == 1 ? 'always' : 'hover'" class="pointer br" :class="tab2 == 1 ? 'checked1' : ''">
            <div @click="tab2 = 1" class="flex flex-v-center">
              <el-image src="/web/image/ali.png" style="height:30px" fit="contain" class="margin-x"></el-image>
              <span>支付宝支付</span>
            </div>
          </el-card>
        </el-col>

      </el-row>

      <div class="margin-lt flex flex-h-center" @click="pay">
        <el-card shadow="hover" class="pay">
          <h4 class="center lt">{{ $t('立即开通') }}</h4>
        </el-card>
      </div>
      <div class="st center margin-t">
        {{ $t('提醒') }}：
        VIP {{ $t('到期后不会自动续费，如要继续使用，请再次购买') }}
      </div>
      <el-divider></el-divider>

      <div class="margin-lt">
        <h2 class="lt center">{{ $t('权益对比') }}</h2>
      </div>


      <div class="margin-lt">

        <el-table id="table" :data="tableData" style="width: 100%">
          <el-table-column prop="col1" :label="$t('特权')">
            <template slot-scope="scope">
              {{ $t(scope.row.col1) }}
            </template>
          </el-table-column>
          <el-table-column prop="col2" :label="$t('非会员')">
            <template slot-scope="scope">
              <div v-for="(col, index) in scope.row.col2.split('-')" :key="index">{{
                $t(col)
              }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="col3" :label="$t('会员')">
            <template slot-scope="scope">
              <div class="bold" v-for="(col, index) in scope.row.col3.split('-')" :key="index">{{
                $t(col)
              }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>



      <div class="margin-lt">
        <h2 class="lt center">{{ $t('海澳VIP权益') }}</h2>
        <div class="flex margin-lt">
          <el-card class="card flex1">
            <h4 class="margin-t color-green"><span class="num">1</span>.{{ $t('无限使用AI评分功能') }}</h4>
            <div class="st1">
              <div>
                ●{{ $t('AI评分准确定位问题，及时改正') }}
              </div>
              <div>
                ●{{ $t('会员AI评测更快出结果') }}
              </div>
            </div>
          </el-card>

          <el-card class="card flex1">
            <h4 class="margin-t color-green"><span class="num">2</span>.{{ $t('无限使用PTE随身听') }}</h4>
            <div class="st1">
              <div>
                ●{{ $t('真题/预测音频随时随地播放，高效利用碎片时间，想听就听') }}
              </div>
              <div>
                ●{{ $t('加深真题/预测音频记忆效果，提升考场作答效率') }}
              </div>
            </div>
          </el-card>

          <el-card class="card flex1">
            <h4 class="margin-t color-green"><span class="num">3</span>.{{ $t('PTE模拟考试及模考成绩分析') }}</h4>
            <div class="st1">
              <div>●{{ $t('提前体验考试流程，并检验备考成果') }}</div>
              <div>●{{ $t('模考后，可微信联系进行模考分析，准确定位问题') }}</div>
            </div>
          </el-card>


          <el-card class="card flex1">

            <h4 class="margin-t color-green"><span class="num">4</span>.{{ $t('最新真题机经PDF下载') }}</h4>
            <div class="st1">
              <div>●{{ $t('题库实时更新，下载最新PTE题库及高频预测题') }}</div>
              <div>●{{ $t('提升考试原题命中率') }}</div>
            </div>
          </el-card>
        </div>


        <div class="flex">
          <el-card class="card flex1">
            <h4 class="margin-t color-green"><span class="num">5</span>.{{ $t('不同题型模板下载') }}</h4>
            <div class="st1">
              <div>●{{ $t('可下载全部模板') }}</div>
            </div>
          </el-card>

          <el-card class="card flex1">
            <h4 class="margin-t color-green"><span class="num">6</span>.{{ $t('任意使用地表最强单词本') }}</h4>
            <div class="st1">
              <div>●{{ $t('含有个人单词本及预设单词库，针对性背诵，不做无用功') }}</div>
              <div>●{{ $t('独有单词星级功能，随心所用') }}</div>
              <div>●{{ $t('单词背诵和听写功能，提升单词学习和背诵效率') }}</div>
            </div>

          </el-card>

          <el-card class="card flex1">
            <h4 class="margin-t color-green"><span class="num">7</span>.{{ $t('题目收藏功能') }}</h4>
            <div class="st1">
              <div>●{{ $t('独有题目收藏星级功能，随心所用') }}</div>
            </div>
          </el-card>

          <el-card class="card flex1">
            <h4 class="margin-t color-green"><span class="num">8</span>.{{ $t('入门讲解视频') }}</h4>
            <div class="st1">
              <div>●{{ $t('经验丰富名师视频详解pte题型') }}</div>
              <div>●{{ $t('新手快速入门，全方位了解pte考试') }}</div>
            </div>
          </el-card>
        </div>

        <div class="st right margin-x">*{{ $t('解释权归海澳英语所有') }}</div>
      </div>

      <div class="margin-lt">
        <h2 class="lt center">{{ $t('常见问题') }}</h2>
        <el-collapse accordion class="margin-t">
          <el-collapse-item>
            <template slot="title">
              <div class="mt">●VIP{{ $t('到期后会自动续费吗') }}？</div>
            </template>
            <div class="st1"> VIP {{ $t('到期后不会自动续费，如要继续使用，请再次购买') }}。</div>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title">
              <h4 class="mt">●{{ $t('支付可以用其他方式吗') }}？</h4>
            </template>
            <div class="st1">{{ $t('目前仅支持微信支付，带来不便敬请谅解') }}。</div>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title">
              <div class="mt">●{{ $t('如何查询VIP购买记录') }}？</div>
            </template>
            <div class="st1">{{ $t('登陆后进入首页→开通VIP→购买记录') }}</div>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title">
              <h4 class="mt">●VIP{{ $t('购买后可以转让或退款吗') }}？</h4>
            </template>
            <div class="st1">VIP{{ $t('属于虚拟产品，购买成功后无法转让或退款') }}。</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-card>
    <pay-dialog ref="payDialog"></pay-dialog>
    <vipcode-dialog ref="vipcodeDialog"></vipcode-dialog>
  </div>
</template>
<script>
import payDialog from '../../components/PayDialog.vue'
import vipcodeDialog from '../../components/VipcodeDialog.vue'

export default {
  components: {
    payDialog, vipcodeDialog
  },
  data() {
    return {
      tab1: 0,
      tab2: 2,
      date: "",
      tableData: [{
        col1: 'AI评分功能',
        col2: '每天最多5次',
        col3: '无次数限制'
      }, {
        col1: 'PTE模考',
        col2: '仅可使用15分钟快速模考',
        col3: '-可使用所有模考-无次数限制'
      }, {
        col1: 'PTE随身听',
        col2: '仅RS题型可用',
        col3: '全部随身听'
      }, {
        col1: '单词本功能',
        col2: '不可使用单词背诵模式和听写模式-不可新建单词本-不可使用星级功能',
        col3: '可使用全部功能'
      }, {
        col1: '最新真题机经PDF下载',
        col2: '可下载往期真题机经PDF',
        col3: '可下载最新真题机经PDF'
      }, {
        col1: '题型模板下载',
        col2: '可下载部分模板',
        col3: '可下载全部模板'
      }, {
        col1: '题目收藏功能',
        col2: '不可使用星级功能',
        col3: '可使用全部功能'
      },]
    };
  },

  created() {
    this.$store.dispatch("fetch_vips");
  },

  methods: {
    to(type) {
      this.$refs.vipcodeDialog.show(type);
    },

    pay() {
      this.$refs.payDialog.show(this.tab1,this.tab2);
    },

    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column)
      if (columnIndex === 1) {
        if (rowIndex === 0) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else if (rowIndex === 1 || rowIndex === 2) {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
  },
};
</script>

<style scoped>
.num {
  font-size: 40px;
  font-weight: 400;
  color: #999;
}

.card {
  margin: 10px;
}

.card:hover {
  cursor: pointer;
  box-shadow: #E6A23C 0px 0px 6px 4px;
}

.price {
  font-size: 30px;
  font-weight: 500;
  color: rgb(251, 158, 97);
  text-align: center;
}

.price1 {
  font-size: 40px;
  margin-right: 10px;
}

.checked .price {
  color: #fff;
}

.checked {
  background: #E6A23C !important;
  color: #fff;
}

.pay {
  width: 200px;
  background: #db7032;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  border-color: #db7032;
}

.pay:hover {
  box-shadow: #ccc 0px 0px 2px 2px;
}

.checked1 {
  border-color: #E6A23C;
}


.checked .st {
  color: #fff;
}

#table>>>th {
  text-align: center;
}

#table>>>.cell {
  text-align: center;
}

.status {
  position: absolute;
  top: 0;
  left: 0;
  background: #E6A23C;
  color: #fff;
  padding: 5px 8px;
  border-bottom-right-radius: 10px;
}

#table>>>thead>tr>th:nth-child(3),
#table>>>tbody>tr>td:nth-child(3) {
  background-color: #E6A23C;
  color: #fff;
}

.pay-type>>>.el-card__body {
  padding: 10px;
}

.color-gray {
  padding: 8px 30px;
  border-radius: 20px;
  background-color: #aaa;
  color: #fff;
}
</style>
